img {
  width: 40px;
  height: 40px;
  padding-left: 6px;
  padding-right: 6px;
}

.searchIcon {
  float: right;
  padding-right: 10px;
}