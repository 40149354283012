html,
body {
  font-family: 'San Francisco' 'Proxima Nova' 'Lato' 'Nexo' sans-serif;
  font-size: small;
  margin: 0;
  --main-spacing: 0px 10px 0px 0px;
  --header-spacing: 0px 0px;  
  --footer-spacing: 10px 10px;  
}

#app {
  height: 100vh;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'header'
    'main'
    'footer';

  > header {
    padding: var(--header-spacing);
    background-color: lightgray;
  }

  > header {
    grid-area: header;
    a {
      cursor: pointer;

      &.disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  > main {
    grid-area: main;
    padding: var(--main-spacing);
    overflow: auto;

  }

  > footer {
    grid-area: footer;
    padding: var(--footer-spacing);
    background-color: lightgray;
  }

}

.PageTitle {
  padding-left: 5px;
  font-size: small;
}

.childSteps {
  list-style-type: lower-alpha;
}

.tinyImage {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}

sup {
  background-color: lightblue;
}

.searchText {
  padding-left: 10px;
}

.videoButton {
  color: blue;
  background: transparent;
  border: 0;
  height: auto;
  padding: 2px;
  position: relative;
  text-align: center;
  text-decoration: underline;
}
