body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
}

.overlay {
  background: rgba(49,49,49,0.3); /* Slight light gray background */
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  padding: 14px 28px;
  border-radius: 3px;
  z-index: 200;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.videoButton {
  color: blue;
  background: transparent;
  border: 0;
  height: auto;
  padding: 2px;
  position: relative;
  text-align: center;
  text-decoration: underline;
}